<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <!-- <li class="breadcrumb-item active">Locations</li> -->
            </ol>
          </div>
          <h4 class="page-title">User</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <!-- <div class="d-flex justify-content-end">
                     <router-link :to="{ name: 'user-create' }">
                        <button
                           type="button"
                           class="
                              btn btn-sm btn-blue
                              waves-effect waves-light
                              mb-2
                           "
                        >
                           <i class="mdi mdi-plus-circle"></i> Add User
                        </button>
                     </router-link>
                  </div> -->

            <place-holder v-if="loading"></place-holder>

            <div class="table-responsive">
              <table class="table table-striped dt-responsive w-100 mb-3" id="user-datatable" v-show="!loading">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Employee No</th>
                    <th>User Type</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(user, index) in users" :key="index">
                    <!-- <tr
                      v-if="
                        user.user_type == 'trainer' ||
                        user.user_type == 'admin' ||
                        user.user_type == 'employee'
                      "
                    > -->
                    <tr>
                      <td>{{ ++index }}</td>
                      <td>{{ user.name }}</td>
                      <td>{{ user.email }}</td>
                      <td>
                        <template v-for="(role, role_index) in user.roles" :key="`role-${role_index}`">
                          <badgeSuccess :name="role.name" class="rounded-pill w-auto"></badgeSuccess>
                        </template>
                      </td>
                      <td>{{ user.employee_id }}</td>
                      <td>{{ user.user_type }}</td>
                      <td>
                        <router-link class="action-icon" :to="{
                          name: 'user-update',
                          params: { id: user.id },
                        }">
                          <i class="mdi mdi-square-edit-outline"></i>
                        </router-link>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Modules
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
//Components
import badgeSuccess from "../../components/shared/badgeSuccess.vue";

export default {
  components: {
    badgeSuccess,
  },
  data() {
    return {
      users: [],
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    async getAllUsers() {
      this.loading = true;
      this.$Progress.start();
      await axios.get(`${this.baseUrl}admin/v1/users`).then((response) => {
        this.users = response.data.data;
        this.loading = false;
        this.$Progress.finish();
      });
      $("#user-datatable").DataTable();
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#user-datatable").DataTable().draw();
    },
  },
  created() {
    this.clearFilter();
    this.getAllUsers();
  },
};
</script>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>